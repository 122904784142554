import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["formInput", "submitButton"];

  connect() {
    this.submitButtonTarget.disabled = true;
    this.boundListeners = this.enableSubmit.bind(this);

    this.formInputTargets.forEach(input => {
      input.addEventListener("input", this.boundListeners);
    });
  }

  disconnect() {
    this.formInputTargets.forEach(input => {
      input.removeEventListener("input", this.boundListeners);
    });
  }

  enableSubmit() {
    this.submitButtonTarget.disabled = false;
  }
}